/* jshint esversion: 6 */
import * as math from 'mathjs'

export default {
  /**
   * Obtain the matrices for conversions between latitude-longitude
   * to x-y.
   *
   * @param {Object[]} gpsPoints
   * @param {number} gpsPoints[].x
   * @param {number} gpsPoints[].y
   * @param {number} gpsPoints[].latitude
   * @param {number} gpsPoints[].longitude
   * @param {number} width
   * @param {number} height
   * @returns {Object} transformation and offset matrices {mTrans:, mOffset:}
   */
  latlngToPointMatrices(gpsPoints, width, height) {
    const matrix = math.matrix(
      [
        [gpsPoints[0].x, gpsPoints[0].y, 0,        0,        1, 0],
        [0,        0,        gpsPoints[0].x, gpsPoints[0].y, 0, 1],
        [gpsPoints[1].x, gpsPoints[1].y, 0,        0,        1, 0],
        [0,        0,        gpsPoints[1].x, gpsPoints[1].y, 0, 1],
        [gpsPoints[2].x, gpsPoints[2].y, 0,        0,        1, 0],
        [0,        0,        gpsPoints[2].x, gpsPoints[2].y, 0, 1]
      ]
    );

    // If the gps markers are not valid
    if (math.det(matrix) === 0) {
      return { mTrans: null, mOffset: null };
    }

    const latLngColumn = math.matrix(
      [
        [gpsPoints[0].longitude], [gpsPoints[0].latitude], [gpsPoints[1].longitude],
        [gpsPoints[1].latitude], [gpsPoints[2].longitude], [gpsPoints[2].latitude]
      ]
    );

    const sol = math.multiply(math.inv(matrix), latLngColumn);

    const mTrans = math.matrix(
      [
        [sol.get([0, 0]), sol.get([1, 0])],
        [sol.get([2, 0]), sol.get([3, 0])]
      ]
    );

    // If the gps markers are not valid
    if (math.det(mTrans) === 0) {
      return { mTrans: null, mOffset: null };
    }

    const mOffset = math.matrix(
      [
        [sol.get([4, 0])],
        [sol.get([5, 0])]
      ]
    );

    return { mTrans: math.inv(mTrans), mOffset };
  },

  /**
   * Convert point from latitude-longitude reference to x-y referenced
   * by a square using matrices for transformation and offset.
   *
   * @param {number} latitude
   * @param {number} longitude
   * @param {number} width
   * @param {number} height
   * @param {Matrix} mTrans
   * @param {Matrix} mOffset
   * @returns {Object} x and y entries
   */
  latlngToPoint(latitude, longitude, width, height, mTrans, mOffset) {
    const points =
      math.multiply(mTrans,
                    math.subtract(math.matrix([[longitude], [latitude]]),
                                  mOffset)
      );

    return {
      x: points.get([0, 0]),
      y: points.get([1, 0])
    };
  },

  /**
   * Convert point from latitude-longitude reference to x-y percentages referenced
   * by a square using matrices for transformation and offset.
   *
   * @param {number} latitude
   * @param {number} longitude
   * @param {number} width
   * @param {number} height
   * @param {Matrix} mTrans
   * @param {Matrix} mOffset
   * @returns {Object} x and y entries representing percentage
   */
  latlngToPercent(latitude, longitude, width, height, mTrans, mOffset) {
    const points =
      math.multiply(mTrans,
                    math.subtract(math.matrix([[longitude], [latitude]]),
                                  mOffset)
      );

    return {
      x: points.get([0, 0]) / width, // * 100,
      y: points.get([1, 0]) / height, // * 100
    };
  },

  /**
   *
   * Translate points from latitude and longitude system to x and y.
   *
   * @param {number} latitude
   * @param {number} longitude
   * @param {Object} image
   * @param {number} image.naturalWidth
   * @param {number} image.naturalHeight
   * @param {number} image.clientWidth
   * @param {number} image.clientHeight
   * @param {Object[]} gpsPoints
   * @param {number} gpsPoints[].x
   * @param {number} gpsPoints[].y
   * @param {number} gpsPoints[].latitude
   * @param {number} gpsPoints[].longitude
   * @returns {Object} x and y entries representing percentage
   */
   translatePoint(latitude, longitude, image, gpsPoints) {
     if(image.naturalWidth === 0 || image.naturalHeight === 0)
       return {x: 0, y: 0};

     const {mTrans, mOffset} =
       this.latlngToPointMatrices(gpsPoints, image.naturalWidth, image.naturalHeight);

     // If the gps markers are not valid
     if (mTrans && mOffset) {
       let {x, y} = this.latlngToPoint(latitude, longitude, image.naturalWidth,
                                       image.naturalHeight, mTrans, mOffset);

       // Transform the points to real image's width and height
       x = x * image.clientWidth / image.naturalWidth;
       y = y * image.clientHeight / image.naturalHeight;

       return {x, y};
     } else {
       // Returns the start point, or null if you do not want it to be showed
       return {x: 0, y: 0};
     }
   },

   /**
    *
    * Translate points from latitude and longitude system to x and y percents.
    *
    * @param {number} latitude
    * @param {number} longitude
    * @param {Object} image
    * @param {number} image.naturalWidth
    * @param {number} image.naturalHeight
    * @param {Object[]} gpsPoints
    * @param {number} gpsPoints[].x
    * @param {number} gpsPoints[].y
    * @param {number} gpsPoints[].latitude
    * @param {number} gpsPoints[].longitude
    * @returns {Object} x and y entries representing percentage
    */
    translatePointToPercent(latitude, longitude, image, gpsPoints) {
      if(image.naturalWidth === 0 || image.naturalHeight === 0)
        return {x: 0, y: 0};

      const {mTrans, mOffset} =
        this.latlngToPointMatrices(gpsPoints, image.naturalWidth, image.naturalHeight);

      // If the gps markers are not valid
      if (mTrans && mOffset) {
        const {x, y} = this.latlngToPercent(latitude, longitude, image.naturalWidth,
                                        image.naturalHeight, mTrans, mOffset);


        return {x, y};
      } else {
        // Returns the start point, or null if you do not want it to be showed
        return {x: 0, y: 0};
      }
    },

    /**
     *
     * Translate points from x and y utm system (percentages) to latitude and longitude.
     *
     * @param {number} x
     * @param {number} y
     * @param {Object} image
     * @param {number} image.naturalWidth
     * @param {number} image.naturalHeight
     * @param {Object[]} gpsPoints
     * @param {number} gpsPoints[].x
     * @param {number} gpsPoints[].y
     * @param {number} gpsPoints[].latitude
     * @param {number} gpsPoints[].longitude
     * @returns {Object} latitude and longitude entries
     */
    translatePercentToLatlng(x, y, image, gpsPoints) {
      if(image.naturalWidth === 0 || image.naturalHeight === 0)
        return {latitude: 0, longitude: 0};

      const {mTrans, mOffset} =
        this.latlngToPointMatrices(gpsPoints, image.naturalWidth, image.naturalHeight);

      // If the gps markers are not valid
      if (mTrans && mOffset) {
        const {latitude, longitude} = this.percentToLatlng(x, y, image.naturalWidth,
                                        image.naturalHeight, mTrans, mOffset);


        return {latitude, longitude};
      } else {
        // Returns the start point, or null if you do not want it to be showed
        return {latitude: 0, longitude: 0};
      }
    },

    /**
     * Convert point from x-y percentages reference to latitude-longitude reference
     * by a square using matrices for transformation and offset.
     *
     * @param {number} x
     * @param {number} y
     * @param {number} width
     * @param {number} height
     * @param {Matrix} mTrans
     * @param {Matrix} mOffset
     * @returns {Object} latitude and longitude entries
     */
    percentToLatlng(x, y, width, height, mTrans, mOffset) {
      const utmX = x * width;
      const utmY = y * height;

      const utmPoint = math.matrix([[utmX], [utmY]]);
      const latlonPoint = math.add(
        math.multiply(math.inv(mTrans), utmPoint), mOffset
      );

      return {
        latitude: latlonPoint.get([1, 0]),
        longitude: latlonPoint.get([0, 0]),
      };
    }
};
