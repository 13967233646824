export interface IPlane {
  id?: string;
  identifier?: string;
  imageContentType?: string;
  image?: any;
  description?: string;
  organizationId?: string;
  organizationIdentifier?: string;
  geoMarkers?: any[];
  zones?: any[];
}

export const defaultValue: Readonly<IPlane> = {};
