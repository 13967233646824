import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Plane from './plane';
import PlaneDetail from './plane-detail';
import PlaneUpdate from './plane-update';
import PlaneDeleteDialog from './plane-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={PlaneDeleteDialog} />
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={PlaneUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={PlaneUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={PlaneDetail} />
      <ErrorBoundaryRoute path={match.url} component={Plane} />
    </Switch>
  </>
);

export default Routes;
