/* jshint esversion: 6 */
import { faVectorSquare, faSync, faArrowsAlt, faExpandAlt, faLock } from '@fortawesome/free-solid-svg-icons';

export default {
  get center() {
    return {lat: 40.416775, lng: -3.703790};
  },

  get corners() {
    const point = this.center;
    return [
      {lat: point.lat, lng: point.lng},
      {lat: point.lat, lng: point.lng + 0.02},
      {lat: point.lat - 0.02, lng: point.lng},
      {lat: point.lat - 0.02, lng: point.lng + 0.02}
    ];
  },

  get bounds() {
    return { center: [0, 0], zoom: 2 };
  },

  contextMenu(callback) {
    return {
      contextmenu: true,
      contextmenuWidth: 120,
      contextmenuItems: [{text: 'Set plane here', callback}]
    }
  },

  get editOptions() {
    return [
      {mode: 'distort', title: 'Distort the image', icon: faVectorSquare},
      {mode: 'rotate', title: 'Rotate the image', icon: faSync},
      {mode: 'translate', title: 'Translate the image', icon: faArrowsAlt},
      {mode: 'scale', title: 'Scale the image', icon: faExpandAlt},
      {mode: 'none', title: 'Lock the image', icon: faLock}
    ]
  }
};
