import { Component } from "react";
import L from "leaflet";
import * as ELG from "esri-leaflet-geocoder";
import { withLeaflet } from "react-leaflet";

class Search extends Component {
  componentDidMount() {
    const map = this.props.leaflet.map;
    const searchControl = new ELG.Geosearch({useMapBounds: false}).addTo(map);
    const searchBtn = document.querySelector('.geocoder-control-input.leaflet-bar');
    const results = new L.LayerGroup().addTo(map);
    const handleClick = this.props.onClick;

    searchBtn.addEventListener('click', function(editEvent){
      handleClick('none');
    });

    searchControl.on("results", function(data) {
      results.clearLayers();
      for (let i = data.results.length - 1; i >= 0; i--) {
        results.addLayer(L.marker(data.results[i].latlng));
      }
    });
  }

  render() {
    return null;
  }
}

// export default Search;
export default withLeaflet(Search);
