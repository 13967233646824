/* jshint esversion: 6 */
import L from 'leaflet';
import 'leaflet-toolbar/dist/leaflet.toolbar.js';
import 'leaflet-draw-toolbar/dist/leaflet.draw-toolbar.js';
import { colors } from './colors';

L.ColorPicker = L.Toolbar2.Action.extend({
  options: {
    toolbarIcon: { className: 'leaflet-color-swatch' }
  },

  initialize(map, shape, options) {
    this._map = map;
    this._shape = shape;

    L.setOptions(this, options);
    L.Toolbar2.Action.prototype.initialize.call(this, map, options);
  },

  addHooks() {
    this._shape.setStyle({ color: this.options.color });
    this._shape.feature.properties.color = this.options.color;
    this.disable();
    this._map.fire('draw:edited', {layers: this._shape});
  },

  _createIcon(toolbar, container, args) {
    const colorSwatch = L.DomUtil.create('div');
    let	width, height;

    L.Toolbar2.Action.prototype._createIcon.call(this, toolbar, container, args);

    L.extend(colorSwatch.style, {
      backgroundColor: this.options.color,
      width: L.DomUtil.getStyle(this._link, 'width'),
      height: L.DomUtil.getStyle(this._link, 'height'),
      border: '3px solid ' + L.DomUtil.getStyle(this._link, 'backgroundColor')
    });

    this._link.appendChild(colorSwatch);

    L.DomEvent.on(this._link, 'click', function() {
      this._map.removeLayer(this.toolbar.parentToolbar);
    }, this);
  }
});

L.CloseToolbar = L.Toolbar2.Action.extend({
  initialize(map, shape, options) {
    this._map = map;
    options = options || {};
    options.toolbarIcon = {
      className: 'leaflet-close-toolbar',
      html: this.options.icon
    };

    L.setOptions(this, options);
    L.Toolbar2.Action.prototype.initialize.call(this, map, options);
  },

  addHooks() {
    this._map.removeLayer(this.toolbar);
  },
});

L.Identifier = L.Toolbar2.Action.extend({
  options: {
    toolbarIcon: { className: 'leaflet-desc-input' }
  },

  initialize(map, shape, options) {
    this._map = map;
    this._shape = shape;

    L.setOptions(this, options);
    L.Toolbar2.Action.prototype.initialize.call(this, map, options);
  },

  addHooks() { },

  _createIcon(toolbar, container, args) {
    const descForm = L.DomUtil.create('div');
    descForm.innerHTML = '<b>Name</b><br/>';
    descForm.innerHTML += `<input type="text" id="identifier" minlength="3" maxlength="50" value="${this._shape.feature.properties.identifier}" />`;
    descForm.innerHTML += '<input type="button" id="saveBtn" value="Save" class="btn btn-primary"/>';
    descForm.innerHTML += '<input type="button" id="cancelBtn" value="Cancel" class="btn btn-secondary"/>';

    L.Toolbar2.Action.prototype._createIcon.call(this, toolbar, container, args);

    this._link.appendChild(descForm);

    L.DomEvent.on(document.querySelector('input#cancelBtn'), 'click', function() {
      this._map.removeLayer(this.toolbar.parentToolbar);
    }, this);

    L.DomEvent.on(document.querySelector('input#saveBtn'), 'click', function() {
      const inputValue = document.querySelector('input#identifier').value;
      if (inputValue.length > 2) {
        this._shape.feature.properties.identifier = inputValue;
        this._map.fire('draw:edited', {layers: this._shape});
        this._map.removeLayer(this.toolbar.parentToolbar);
      }
    }, this);
  }
});

export default {
  extendedActions(extendedIcons) {
    return [
      L.Toolbar2.Action.extendOptions({
        toolbarIcon: {
          className: 'leaflet-identifier',
          html: extendedIcons.text
        },
        subToolbar: new L.Toolbar2({ actions: [
          L.Identifier.extendOptions()
        ]})
      }),
      L.Toolbar2.Action.extendOptions({
        toolbarIcon: {
          className: 'leaflet-color-picker',
          html: extendedIcons.colorpicker
        },
        subToolbar: new L.Toolbar2({
          actions: colors.map(color => L.ColorPicker.extendOptions({ color }))
        })
      }),
      L.Toolbar2.EditAction.Popup.Edit,
      L.Toolbar2.EditAction.Popup.Delete,
      L.CloseToolbar.extendOptions({ icon: extendedIcons.close })
    ];
  },

  addToolbar(latlng, extendedIcons) {
    return (
      new L.Toolbar2.EditToolbar.Popup(latlng, {
        actions: this.extendedActions(extendedIcons)
      })
    )
  }
}
