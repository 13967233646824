export interface IZone {
  id?: string;
  identifier?: string;
  description?: string;
  floor?: string;
  color?: string;
  planeId?: string;
  planeIdentifier?: string;
}

export const defaultValue: Readonly<IZone> = {};
