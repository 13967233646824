export interface IOrganization {
  id?: string;
  indentifier?: string;
  description?: string;
  topic?: string;
  consumerId?: string;
  organizationWizzieId?: number;
  organizationWizzieName?: string;
}

export const defaultValue: Readonly<IOrganization> = {};
