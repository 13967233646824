import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './zone.reducer';
import { IZone } from 'app/shared/model/zone.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IZoneDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ZoneDetail = (props: IZoneDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { zoneEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="planesApp.zone.detail.title">Zone</Translate> [<b>{zoneEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="identifier">
              <Translate contentKey="planesApp.zone.identifier">Identifier</Translate>
            </span>
          </dt>
          <dd>{zoneEntity.identifier}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="planesApp.zone.description">Description</Translate>
            </span>
          </dt>
          <dd>{zoneEntity.description}</dd>
          <dt>
            <span id="floor">
              <Translate contentKey="planesApp.zone.floor">Floor</Translate>
            </span>
          </dt>
          <dd>{zoneEntity.floor}</dd>
          <dt>
            <span id="color">
              <Translate contentKey="planesApp.zone.color">Color</Translate>
            </span>
          </dt>
          <dd>{zoneEntity.color}</dd>
          <dt>
            <Translate contentKey="planesApp.zone.plane">Plane</Translate>
          </dt>
          <dd>{zoneEntity.planeId ? zoneEntity.planeId : ''}</dd>
        </dl>
        <Button tag={Link} to="/zone" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/zone/${zoneEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ zone }: IRootState) => ({
  zoneEntity: zone.entity
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ZoneDetail);
