import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, Collapse } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, setFileData, openFile, byteSize, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { IRootState } from 'app/shared/reducers';

import { IOrganization } from 'app/shared/model/organization.model';
import { getEntities as getOrganizations } from 'app/entities/organization/organization.reducer';
import { getEntity, updateEntity, createEntity, setBlob, setGps, setZones, reset } from './plane.reducer';
import { IPlane } from 'app/shared/model/plane.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

import PlaneMap from './plane-map';
import PlaneZones from './plane-zones';

export interface IPlaneUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const PlaneUpdate = (props: IPlaneUpdateProps) => {
  const [organizationId, setOrganizationId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [isOpenMap, setIsOpenMap] = useState(true);
  const [isOpenPlane, setIsOpenPlane] = useState(true);

  const { planeEntity, organizations, loading, updating } = props;
  const { image, imageContentType, identifier, id, geoMarkers, zones } = planeEntity;

  const fileInput = React.createRef<HTMLInputElement>();

  const toggleMap = () => setIsOpenMap(!isOpenMap);
  const togglePlane = () => setIsOpenPlane(!isOpenPlane);

  const handleClose = () => {
    props.history.push('/plane');
  };

  useEffect(() => {
    if (!isNew) {
      props.getEntity(props.match.params.id);
    }

    props.getOrganizations();
  }, []);

  const onBlobChange = (isAnImage, name) => event => {
    setFileData(event, (contentType, data) => props.setBlob(name, data, contentType), isAnImage);
  };

  const clearInput = () => {
    if (fileInput) {
      fileInput.current.value = '';
    }
  };

  const clearBlob = name => () => {
    clearInput();
    props.setGps(undefined);
    props.setBlob(name, undefined, undefined);
  };

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...planeEntity,
        ...values
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  const updatePlaneMap = data => {
    if (data.zones) { props.setZones(data.zones); }
    if (data.corners) { props.setGps(data.corners); }
  }

  return (
    <div>
      <Row className="justify-content-center card-title">
        <Col md="8">
          <h2 id="planesApp.plane.home.createOrEditLabel">
            <Translate contentKey="planesApp.plane.home.createOrEditLabel">Create or edit a Plane</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center card-content">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : planeEntity} onSubmit={saveEntity}>
              <Row>
                <Col md="6">
                  <AvGroup>
                    <Label id="identifierLabel" for="plane-identifier">
                      <Translate contentKey="planesApp.plane.identifier">Identifier</Translate>
                    </Label>
                    <AvField
                      id="plane-identifier"
                      type="text"
                      name="identifier"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') },
                        minLength: { value: 3, errorMessage: translate('entity.validation.minlength', { min: 3 }) },
                        maxLength: { value: 50, errorMessage: translate('entity.validation.maxlength', { max: 50 }) }
                      }}
                    />
                  </AvGroup>
                </Col>
                <Col md="6">
                  <AvGroup>
                    <Label for="plane-organization">
                      <Translate contentKey="planesApp.plane.organization">Organization</Translate>
                    </Label>
                    <AvInput
                      id="plane-organization"
                      type="select"
                      className="form-control"
                      name="organizationId"
                      validate={{
                        required: { value: true, errorMessage: translate('entity.validation.required') }
                      }}>
                      <option value="" key="0" />
                      {organizations
                        ? organizations.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.indentifier}>
                              {otherEntity.indentifier}
                            </option>
                          ))
                        : null}
                    </AvInput>
                  </AvGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <AvGroup>
                    <Label id="descriptionLabel" for="plane-description">
                      <Translate contentKey="planesApp.plane.description">Description</Translate>
                    </Label>
                    <AvField id="plane-description" type="text" name="description" />
                  </AvGroup>
                </Col>
                <Col md="12">
                  <AvGroup>
                    <Label id="imageLabel" for="image">
                      <Translate contentKey="planesApp.plane.image">Image</Translate>
                    </Label>
                    <br />
                    <div>
                      <Row>
                        <Col sm="9" lg="10" className="mt-2">
                          <AvInput type="file" id="file_image" name="file_image" ref={fileInput} onChange={onBlobChange(false, 'image')} />
                          {image ? (
                            <AvInput type="hidden" name="image" value={image} />
                          ) : null}
                        </Col>
                        {image ? (
                          <Col sm="3" lg="2" className="text-nowrap">
                            <Button color="danger" className="p-2 float-right" onClick={clearBlob('image')}>
                              <FontAwesomeIcon icon="times-circle" />
                            </Button>
                            <Button color="primary" className="p-2 mr-1 float-right" onClick={openFile(imageContentType, image)}>
                              <FontAwesomeIcon icon="eye" />
                            </Button>
                          </Col>
                        ) : null}
                      </Row>
                    </div>
                  </AvGroup>
                </Col>
              </Row>

              <h3 style={{ marginTop: '1.5rem' }}>
                <Button color="danger" onClick={toggleMap} className="px-2 py-1 mb-1">
                  {isOpenMap ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
                </Button> Map editor
              </h3>
              <Collapse isOpen={isOpenMap}>
                <PlaneMap plane={{identifier, id}} url={image} corners={geoMarkers} zones={zones} onChange={updatePlaneMap} />
              </Collapse>

              {image ? (
                <>
                  <h3 style={{ marginTop: '1.5rem' }}>
                    <Button color="danger" onClick={togglePlane} className="px-2 py-1 mb-1">
                      {isOpenPlane ? <FontAwesomeIcon icon={faChevronDown} inverse /> : <FontAwesomeIcon icon={faChevronRight} inverse/>}
                    </Button> Plane editor
                  </h3>
                  <Collapse isOpen={isOpenPlane}>
                    <PlaneZones plane={{identifier, id}} url={image} corners={geoMarkers} zones={zones} onChange={updatePlaneMap} />
                  </Collapse><br/>
                </>
              ) : null}
              <Button tag={Link} id="cancel-save" to="/plane" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  organizations: storeState.organization.entities,
  planeEntity: storeState.plane.entity,
  loading: storeState.plane.loading,
  updating: storeState.plane.updating,
  updateSuccess: storeState.plane.updateSuccess
});

const mapDispatchToProps = {
  getOrganizations,
  getEntity,
  updateEntity,
  setBlob,
  setGps,
  setZones,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PlaneUpdate);
