/* jshint esversion: 6 */
import L from 'leaflet';

export default {
  removeFromDOM(selector) {
    const element = document.querySelector(selector);
    if (element) {element.remove()};
  },

  clearLeafletImage() {
    this.removeFromDOM('path.leaflet-interactive.leaflet-path-draggable');
    this.removeFromDOM('img.leaflet-image-layer.leaflet-zoom-animated');
  },

  preventBehavior(action, selector) {
    const element = document.querySelector(selector);
    element.addEventListener(action, function(editEvent){
      editEvent.preventDefault();
    });
  },

  manageDragging(leafletElement, selector) {
    const element = document.querySelector(selector);
    if (element) {
      // Disables map dragging while changing opacity.
      element.addEventListener('mouseenter', () => leafletElement.dragging.disable());
      // Enables map dragging after changing opacity.
      element.addEventListener('mouseleave', () => leafletElement.dragging.enable());
    }
  },

  centerMap(e, imgCorners) {
    const topLeft = imgCorners[0];

    const delta = Object.keys(e.latlng).reduce((a, k) => {
      a[k] = e.latlng[k] - topLeft[k];
      return a;
    }, {});

    const corners = imgCorners.map(corner => ({
      lat: corner['lat'] + delta['lat'],
      lng: corner['lng'] + delta['lng']
    }));

    return corners;
  },

  propsToCornersMap(corners) {
    return corners.map(
      corner => new L.latLng(
        corner.geoCoordinate.latitude,
        corner.geoCoordinate.longitude
      )
    );
  },

  mapCornersToProps(corners) {
    const coordinates = this.imageCoordinates;

    return corners.map(
      (corner, i) => ({
        geoCoordinate: { latitude: corner.lat, longitude: corner.lng },
        coordinate: coordinates[i]
      })
    );
  },

  zonesToCorners(zones) {
    const point = zones.map(zone => zone.geoCoordinates).flat()[0];
    return [
      {lat: point.latitude + 0.01, lng: point.longitude - 0.01},
      {lat: point.latitude + 0.01, lng: point.longitude + 0.01},
      {lat: point.latitude - 0.01, lng: point.longitude - 0.01},
      {lat: point.latitude - 0.01, lng: point.longitude + 0.01}
    ];
  },

  get imageCoordinates() {
    const image = document.querySelector('.leaflet-overlay-pane>img.leaflet-image-layer');
    return [
      {x: 0, y: 0},
      {x: image.naturalWidth, y: 0},
      {x: 0, y: image.naturalHeight},
      {x: image.naturalWidth, y: image.naturalHeight}
    ];
  },

  propsToZonesMap(zones) {
    return zones.map(
      zone => ({
        type: "Feature",
        properties: zone,
        geometry: {
          type: "Polygon",
          coordinates: [
            zone.geoCoordinates.map(point => ([point.longitude, point.latitude]))
          ]
        }
      })
    );
  },

  mapZonesToProps(geojsonData) {
    // Remove repeated first-last point
    return geojsonData.features.map(
      feature => ({
        ...feature.properties,
        geoCoordinates: feature.geometry.coordinates[0].slice(0, -1).map(
          coordinate => ({latitude: coordinate[1], longitude: coordinate[0]}))
      })
    )
  },

  setNewGeoJsonFeature(layer, plane, zonesCount) {
    layer.feature = {
      type: "Feature",
      properties: {
        color: '#3388ff',
        identifier: `Zone ${zonesCount}#${layer._leaflet_id} - ${plane.identifier || 'New plane'}`,
        planeId: plane.id
      },
      geometry: {
        type: "Polygon",
        coordinates: [
          layer._latlngs[0].map(point => ([point.lng, point.lat]))
        ]
      }
    }
  }
};
