import React, { Component } from 'react';
import { renderToString } from 'react-dom/server';
import { Map, TileLayer, FeatureGroup } from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import ReactDistortableImageOverlay from 'react-leaflet-distortable-imageoverlay';
import FullscreenControl from 'react-leaflet-fullscreen';
import 'leaflet-contextmenu';
import Slider from 'react-rangeslider';
import cs from 'classnames';
import drawUtils from './utils/draw';
import defaults from './utils/defaults';
import toolbar from './utils/toolbar';
import Search from './utils/search';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeDropper, faTimes, faFont } from '@fortawesome/free-solid-svg-icons';

// TODO: import into the scss file
import './plane-map.scss';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw-src.css';
import 'react-rangeslider/lib/index.css';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
import 'leaflet-toolbar/dist/leaflet.toolbar.css';
import 'leaflet-draw-toolbar/dist/leaflet.draw-toolbar.css';
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import 'react-leaflet-fullscreen/dist/styles.css';

// work around broken icons when using webpack
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

type PlaneMapState = {
  editMode: string,
  opacity: number,
  corners: any[]
}

type PlaneMapProps = {
  url?: string,
  corners?: any[],
  zones?: any[],
  onChange: any,
  plane?: any
}

export default class PlaneMap extends Component<PlaneMapProps, PlaneMapState> {
  _editableFG = null;
  _imageRef = null;
  _mapRef = null;
  reloading = false;

  constructor(props: PlaneMapProps) {
    super(props)

    this.state = {
      editMode: 'none',
      opacity: 0.8,
      corners: this.corners
    };
  }

  get corners() {
    if (this.props.corners) {
      return drawUtils.propsToCornersMap(this.props.corners);
    } else if (this.props.zones && this.props.zones.length > 0) {
      return drawUtils.zonesToCorners(this.props.zones);
    } else {
      return defaults.corners;
    }
  }

  get zones() {
    return this.props.zones ?
      drawUtils.propsToZonesMap(this.props.zones) : [];
  }

  get bounds() {
    const bounds = (this.props.url || this.props.zones) && this.state.corners;

    if (bounds) {
      return { bounds };
    } else {
      return defaults.bounds;
    }
  }

  get contextMenu() {
    return defaults.contextMenu(this._centerMap);
  }

  componentDidUpdate(prevProps, prevState) {
    // Detects existing image changes or its removal from the form.
    this.imageInputChanged(prevProps.url, this.props.url);
    // Detects new image url loaded at map and initializes its default gpsMarkers.
    this.newImageLoaded(prevProps.url, this.props.url);
    // Clear zones if props changes from the plane-zones component.
    this.updateZones(prevProps.zones);
  }

  newImageLoaded(prevUrl, newUrl) {
    if (newUrl && (prevUrl !== newUrl || this.reloading)) {
      const element = document.querySelector('.leaflet-overlay-pane>img.leaflet-image-layer');
      if (element) {
        element.addEventListener('load', () => this.onCornersUpdated(this.corners));
        this.reloading = false;
      } else {
        this.reloading = true;
      }
    }
  }

  imageInputChanged(prevUrl, newUrl) {
    if (prevUrl && prevUrl !== newUrl) {
      this.handleEditMode('none');
      drawUtils.clearLeafletImage();
    }
  }

  updateZones(prevZones) {
    if (this._editableFG && JSON.stringify(prevZones) !== JSON.stringify(this.props.zones)) {
      const geojsonData = this._editableFG.leafletElement.toGeoJSON();
      const data = drawUtils.mapZonesToProps(geojsonData);
      if (JSON.stringify(data) !== JSON.stringify(this.props.zones)) {
        this._editableFG.leafletElement.clearLayers();
        this._editableFG = null;
      }
    }
  }

  handleEditMode(editMode) {
    this.setState({ editMode });
  }

  handleOpacity(value) {
    this.setState({ opacity: value / 100.0 });
  }

  manageDragging() {
    if (this._mapRef) {
      drawUtils.manageDragging(this._mapRef.leafletElement, '.opacity-container');
    }
  }

  onCornersUpdated(corners) {
    if(this._imageRef) {
      this._imageRef.state.corners = corners;
      this.manageDragging();
      if (this._mapRef.leafletElement._zoom < 10) {
        this._mapRef.leafletElement.fitBounds(corners);
      }
    }

    this.props.onChange({corners: drawUtils.mapCornersToProps(corners)});
  }

  _centerMap = (e) => {
    const imgCorners = this._imageRef ? this._imageRef.state.corners : this.state.corners;
    const corners = drawUtils.centerMap(e, imgCorners)
    this.onCornersUpdated(corners);
    this.handleEditMode('translate');
  }

  _onFeatureGroupReady = (reactFGref) => {

    // populate the leaflet FeatureGroup with the geoJson layers
    const leafletGeoJSON = new L.GeoJSON(this.geoJson, {
      style(feature) {
        return { color: feature.properties.color };
      },
    });
    const leafletFG = reactFGref.leafletElement;

    leafletGeoJSON.eachLayer( (layer) => {
      this.addLayerMenu(layer);
      leafletFG.addLayer(layer);
    });

    // store the ref for future access to content
    this._editableFG = reactFGref;
  }

  get geoJson() {
    return {
      type: "FeatureCollection",
      features: this.zones
    }
  }

  addLayerMenu(layer) {
    // TODO: set it into toolbar.js
    const extendedIcons = {
      colorpicker: renderToString(<FontAwesomeIcon icon={faEyeDropper} onClick={() => event.preventDefault()}/>),
      close: renderToString(<FontAwesomeIcon icon={faTimes} onClick={() => event.preventDefault()}/>),
      text: renderToString(<FontAwesomeIcon icon={faFont} onClick={() => event.preventDefault()}/>)
    }

    layer.on('click', function(event) {
      // Prevent edit a zone while removing
      if(!document.querySelector('.leaflet-draw-tooltip')) {
        toolbar.addToolbar(event.latlng, extendedIcons).addTo(layer._map, layer);
        drawUtils.preventBehavior('click', 'a.leaflet-toolbar-icon.leaflet-draw-edit-edit');
      }
    });
  }

  _onCreated = (e) => {
    const zonesCount = Object.keys(this._editableFG.leafletElement._layers).length;
    drawUtils.setNewGeoJsonFeature(e.layer, this.props.plane, zonesCount);
    this.addLayerMenu(e.layer);
    this._onChange();
    // Updates the corners if there is no image loaded, when the first zone is created.
    if (!this.props.url && !this.props.corners && this.props.zones.length === 1) {
      this.setState({corners: drawUtils.zonesToCorners(this.props.zones)});
    }
  }

  _onEdited = (e) => {
    this._onChange();
  }

  _onDeleted = (e) => {
    this._editableFG.leafletElement.removeLayer(e.layers.getLayers()[0]);
    this._onChange();
  }

  _onChange = () => {

    // this._editableFG contains the edited geometry, which can be manipulated through the leaflet API
    const { onChange } = this.props;

    if (!this._editableFG || !onChange) {
      return;
    }

    const geojsonData = this._editableFG.leafletElement.toGeoJSON();
    onChange({zones: drawUtils.mapZonesToProps(geojsonData)});
  }

  render() {
    return (
      <Map {...this.bounds} {...this.contextMenu} ref={(map) => this._mapRef = map} whenReady={this.manageDragging.bind(this)}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          maxZoom={22} maxNativeZoom={18}
          url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <FullscreenControl position="topleft" />
        <Search onClick={this.handleEditMode.bind(this)} />

        {this.props.url && this.state.corners ?
          <ReactDistortableImageOverlay
            ref={(image) => this._imageRef = image}
            url={`data:image/jpeg;base64,${this.props.url}`}
            editMode={this.state.editMode}
            opacity={this.state.opacity}
            corners={this.state.corners}
            onCornersUpdated={this.onCornersUpdated.bind(this)}
          /> : null}

        <FeatureGroup ref={(reactFGref) => {
            if(reactFGref && this._editableFG === null) {
              this._onFeatureGroupReady(reactFGref);
            } else {
              return null;
            }
          }}>
          <EditControl
            position='topright'
            onEdited={this._onEdited}
            onCreated={this._onCreated}
            onDeleted={this._onDeleted}
            draw={{
              circle: false,
              marker: false,
              circlemarker: false
            }}
            edit={{edit: false}}
          />
          {this.props.url && this.state.corners ?
            <div className="leaflet-right leaflet-custom">
              <div className="leaflet-control">
                <div className="leaflet-bar">
                  {defaults.editOptions.map(option => {
                    return (
                      <span key={option.mode} title={option.title}
                        onClick={() => this.handleEditMode(option.mode)}
                        className={cs({selected: this.state.editMode === option.mode})}>
                        <FontAwesomeIcon icon={option.icon} />
                      </span>
                    )
                  })}
                </div>
                <div className="opacity-container" title="Change the opacity">
                  <Slider
                    min={0} max={100} orientation="vertical"
                    format={() => `Opacity: ${this.state.opacity * 100}`}
                    value={this.state.opacity * 100.0}
                    onChange={this.handleOpacity.bind(this)}
                  />
                </div>
              </div>
            </div> : null}
        </FeatureGroup>
      </Map>
    )
  }
}
