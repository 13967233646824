import './home.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { connect } from 'react-redux';
import { Row, Col, Alert } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';

export type IHomeProp = StateProps;

export const Home = (props: IHomeProp) => {
  const { account } = props;

  return (
    <Row>
      <Col md="12">
        {account && account.login ? (
          <>
            <h1 className="p-3 mb-0">
              <Translate contentKey="home.title" interpolate={{ username: account.login }}>
                Welcome, {account.login}!
              </Translate>
            </h1>
            <div className="card-content">
              <Alert color="success">
                <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                  You are logged in as user {account.login}.
                </Translate>
              </Alert>
            </div>
          </>
        ) : (
          <>
            <h1 className="p-3 mb-0">
              <Translate contentKey="home.default">
                Welcome!
              </Translate>
            </h1>
            <div className="card-content">
              <Alert color="warning">
                <Link to="/login" className="alert-link">
                  <Translate contentKey="global.messages.info.authenticated.clink">Sign in</Translate>
                </Link>
                <Translate contentKey="global.messages.info.authenticated.continue"> to continue.</Translate>
              </Alert>

              <Alert color="warning">
                <Translate contentKey="global.messages.info.register.noaccount">You do not have an account yet?</Translate>&nbsp;
                <Link to="/account/register" className="alert-link">
                  <Translate contentKey="global.messages.info.register.link">Register a new account</Translate>
                </Link>
              </Alert>
            </div>
          </>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(Home);
