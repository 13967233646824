import axios from 'axios';
import {
  parseHeaderForLinks,
  loadMoreDataWhenScrolled,
  ICrudGetAction,
  ICrudGetAllAction,
  ICrudPutAction,
  ICrudDeleteAction
} from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { IPlane, defaultValue } from 'app/shared/model/plane.model';

export const ACTION_TYPES = {
  FETCH_PLANE_LIST: 'plane/FETCH_PLANE_LIST',
  FETCH_PLANE: 'plane/FETCH_PLANE',
  CREATE_PLANE: 'plane/CREATE_PLANE',
  UPDATE_PLANE: 'plane/UPDATE_PLANE',
  DELETE_PLANE: 'plane/DELETE_PLANE',
  SET_BLOB: 'plane/SET_BLOB',
  SET_GPS: 'plane/SET_GPS',
  SET_ZONES: 'plane/SET_ZONES',
  RESET: 'plane/RESET'
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<IPlane>,
  entity: defaultValue,
  links: { next: 0 },
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

export type PlaneState = Readonly<typeof initialState>;

// Reducer

export default (state: PlaneState = initialState, action): PlaneState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_PLANE_LIST):
    case REQUEST(ACTION_TYPES.FETCH_PLANE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(ACTION_TYPES.CREATE_PLANE):
    case REQUEST(ACTION_TYPES.UPDATE_PLANE):
    case REQUEST(ACTION_TYPES.DELETE_PLANE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true
      };
    case FAILURE(ACTION_TYPES.FETCH_PLANE_LIST):
    case FAILURE(ACTION_TYPES.FETCH_PLANE):
    case FAILURE(ACTION_TYPES.CREATE_PLANE):
    case FAILURE(ACTION_TYPES.UPDATE_PLANE):
    case FAILURE(ACTION_TYPES.DELETE_PLANE):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_PLANE_LIST): {
      const links = parseHeaderForLinks(action.payload.headers.link);

      return {
        ...state,
        loading: false,
        links,
        entities: loadMoreDataWhenScrolled(state.entities, action.payload.data, links),
        totalItems: parseInt(action.payload.headers['x-total-count'], 10)
      };
    }
    case SUCCESS(ACTION_TYPES.FETCH_PLANE):
      return {
        ...state,
        loading: false,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.CREATE_PLANE):
    case SUCCESS(ACTION_TYPES.UPDATE_PLANE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data
      };
    case SUCCESS(ACTION_TYPES.DELETE_PLANE):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: {}
      };
    case ACTION_TYPES.SET_BLOB: {
      const { name, data, contentType } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          [name]: data,
          [name + 'ContentType']: contentType
        }
      };
    }
    case ACTION_TYPES.SET_GPS: {
      const { data } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          geoMarkers: data
        }
      };
    }
    case ACTION_TYPES.SET_ZONES: {
      const { data } = action.payload;
      return {
        ...state,
        entity: {
          ...state.entity,
          zones: data
        }
      };
    }
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

const apiUrl = 'api/planes';

// Actions

export const getEntities: ICrudGetAllAction<IPlane> = (page, size, sort) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_PLANE_LIST,
    payload: axios.get<IPlane>(`${requestUrl}${sort ? '&' : '?'}cacheBuster=${new Date().getTime()}`)
  };
};

export const getEntity: ICrudGetAction<IPlane> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_PLANE,
    payload: axios.get<IPlane>(requestUrl)
  };
};

export const createEntity: ICrudPutAction<IPlane> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_PLANE,
    payload: axios.post(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const updateEntity: ICrudPutAction<IPlane> = entity => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_PLANE,
    payload: axios.put(apiUrl, cleanEntity(entity))
  });
  return result;
};

export const deleteEntity: ICrudDeleteAction<IPlane> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_PLANE,
    payload: axios.delete(requestUrl)
  });
  return result;
};

export const setBlob = (name, data, contentType?) => ({
  type: ACTION_TYPES.SET_BLOB,
  payload: {
    name,
    data,
    contentType
  }
});

export const setGps = data => ({
  type: ACTION_TYPES.SET_GPS,
  payload: {
    data
  }
});

export const setZones = data => ({
  type: ACTION_TYPES.SET_ZONES,
  payload: {
    data
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
