import React, { Component } from 'react';
import ReactImageAnnotate from 'react-image-annotate';
import { v4 as uuidv4 } from 'uuid';
import gpsUtils from './utils/gps';
import './plane-zones.scss';

type PlaneZonesProps = {
  url?: string,
  corners?: any[],
  zones?: any[],
  onChange: any,
  plane?: any
}

export default class PlaneZones extends Component<PlaneZonesProps> {
  get zones() {
    return this.props.zones?.map(zone => ({
      type: 'polygon',
      points: this.coordinatesToZones(zone.geoCoordinates),
      cls: zone.identifier,
      color: zone.color,
      editingLabels: false,
      highlighted: false,
      id: zone.id || uuidv4(), // Add a temporal id for edit purposes
      open: false
    }));
  }

  coordinatesToZones(geoCoordinates) {
    const zone = [];
    const image = document.querySelector(`img[src="data:image/jpeg;base64,${this.props.url}"]`);
    if (image) {
      const gpsMarkers = this.props.corners.map(c => ({...c.coordinate, ...c.geoCoordinate}));

      geoCoordinates.map(coordinate => {
        const latitude = coordinate.latitude;
        const longitude = coordinate.longitude;

        const { x, y } = gpsUtils.translatePointToPercent(
          latitude,
          longitude,
          image as HTMLImageElement,
          gpsMarkers
        );

        zone.push([x, y]);
      });
    }

    return zone;
  }

  _onSave = (e) => {
    const image = e.images.find(i => i.src === `data:image/jpeg;base64,${this.props.url}`);
    const imageDimension = {
      naturalWidth: image?.pixelSize.w || 0,
      naturalHeight: image?.pixelSize.h || 0
    };

    const regions = JSON.parse(JSON.stringify(image?.regions));
    const gpsMarkers = this.props.corners.map(c => ({...c.coordinate, ...c.geoCoordinate}));

    const geojsonData = regions?.map(region => ({
      color: region.color,
      identifier: region.cls || `Zone - ${region.id}`,
      id: region.id,
      description: null,
      floor: null,
      planeId: this.props.plane.id,
      planeIdentifier: this.props.plane.identifier,
      geoCoordinates: region.points.map(
        coordinate => (gpsUtils.translatePercentToLatlng(
          coordinate[0],
          coordinate[1],
          imageDimension,
          gpsMarkers
        )
      ))
    }));

    this.props.onChange({zones: geojsonData});
  }

  render() {
    const zones = this.zones;
    return (
      <ReactImageAnnotate
        selectedImage={`data:image/jpeg;base64,${this.props.url}`}
        images={[
          { src: `data:image/jpeg;base64,${this.props.url}`,
            name: 'Plane 1',
            pixelSize: {
              w: 750,
              h: 500
            },
            regions: zones
          }
        ]}
        regionClsList={['Zone A', 'Zone B', 'Zone C', 'Zone D', 'Zone E']}
        enabledTools={['select', 'create-polygon']}
        onExit={this._onSave}
        key={`${JSON.stringify(zones)}-${JSON.stringify(this.props.url)}`}
      />
    )
  }
}
